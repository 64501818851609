import $ from "jquery";
import _ from "lodash";
import {tokenCookies} from "../Cookie/Cookie";

const productionHandler = (error, errorType, message) => {
    if (errorType === "parsererror") {
        window.location.reload();
    }
}

export default function RequestComopps(cacheRequest) {
    this.delayClass = "ajax-delay";
    this.main = $("body");

    this.sendRequestData = obj => {
        return new Promise((resolve, reject) => {
            this.sendRequest(obj, false).then(data => {
                resolve({ header: data[1], data: data[0] });
            }, reject);
        });
    };

    this.sendRequest = (obj, delay = true) => {
        return new Promise((resolve, reject) => {
            const getCacheKey = ({ url, data }) => `${url}?${$.param(data)}`;
            if (delay) {
                if (this.main.hasClass(this.delayClass)) {
                    return;
                }
                this.main.addClass(this.delayClass);
            }

            if (!delay && obj.type.toLowerCase() === "get") {
                const cacheKey = getCacheKey(obj);
                const dataFromTheCache = cacheRequest.get(cacheKey);
                if (dataFromTheCache) {
                    setTimeout(() => {
                        resolve(dataFromTheCache);
                        this.main.removeClass(this.delayClass);
                    }, 0);

                    return;
                }
            }

            const alias = (obj.url === 'clients' && obj.type?.toLowerCase() === 'post') ? 'auth' : 'widget';
            const token = tokenCookies.get();

            $.ajax({
                url: `https://${process.env.HOST_NAME}/api/${alias}/${obj.url}?token=${token}`,
                data: obj.data,
                type: obj.type,
                dataType: obj.dataType || "JSON",
                processData: obj.hasOwnProperty("processData") ? obj.processData : true,
                contentType: obj.hasOwnProperty("contentType")
                    ? obj.contentType
                    : "application/x-www-form-urlencoded; charset=UTF-8",
            })
                .done((data, textMessage, request) => {
                    const successRequestStatus = 200;
                    if (request.status === successRequestStatus) {
                        if (obj.type.toLowerCase() === "get") {
                            const cacheKey = getCacheKey(obj);
                            cacheRequest.set(cacheKey, [data.message, request]);
                        }
                    }
                    resolve([data.message, request]);
                })
                .fail((error, errorType, message) => {
                    if(process.env.NODE_ENV === 'production') {
                        productionHandler(error, errorType, message);
                    }

                    reject(_.get(error, "responseJSON.errors", "Error."));
                })
                .always(() => {
                    this.main.removeClass(this.delayClass);
                });
        });
    };

    //     this.autoloadFile = (url, params) => {
    //         url += !!params ? "?" + $.param(params) : "";

    //         window.location = url;
    //     };
}
